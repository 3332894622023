<template>
    <div>
        <b-card class="mb-3" v-if="person.is_second_applicant == 1 && person.confirmed_applicant_type == 0">
            <b-alert variant="warning" show class="mb-0">
                <b-row class="d-flex align-items-center">
                    <b-col cols="12" md="8">
                        <div class="text-md-start">{{trans('sh-pick-applicant-type', 292)}}</div> 
                    </b-col>
                    <b-col>
                        <app-radio-group v-model="person.applicant_type" reduceValue :options="applicant_type_list" validatorRules="required" validatorName="Applicant type"/>
                    </b-col>
                </b-row>
            </b-alert> 
        </b-card>

        <b-card>
            <b-overlay :show="loading_list" :opacity="0.6">
                <b-row>
                    <b-col start>
                        <div class="app-card-profile-title">
                            {{trans('services',280)}} <span v-if="total > 0">({{total}}) </span> <span v-if="person !== null"> - {{ person.name }}</span>
                        </div>
                        
                        <app-row-left-label class="mt-3" v-if="cPerson.id_favourite_data_client == null">
                            <app-check-box v-model="cPerson.favourite_client_check" value="1" unchecked-value="0" class="font-weight-bold" :rightLabel="trans('sh-fw-confirm-shifts-mobile-app', 292)"/>
                        </app-row-left-label>
                    </b-col>                     
                    
                    <b-col cols="auto" end class="d-flex align-items-center">

                        <div style="min-width:24px; max-width:24px; min-height: 26px">  
                                
                                <b-link v-if="!loading_list" style="color: #98A2B3; cursor:pointer; outline:none !important;" @click="checkPerson">
                                    <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
                                </b-link>

                                <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
                            </div>
                    </b-col>
                </b-row>
                
                <b-row class="mt-3" v-if="show_padding">
                    <b-col start>

                        <b-list-group flush>
                            <!-- item -->
                            <b-list-group-item class="app-checklist-status-item " v-bind:class="{ 'app-checklist-danger':  (item.status < 0 || item.status == null) || item.atw_status == 0, 'app-checklist-success': item.status == 1, 'app-checklist-warning': item.status == 0 && item.atw_status == 1}" v-for="item in cShiftsData" :key="item.id_shift_data_main">
                                <b-row align-v="center" class="ml-1">
                                    <b-col>
                                        <b-row align-v="center">

                                            <b-col cols="3" class="ml-1 pl-0">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 class="mb-0 text-sm font-weight-600" >{{item.client_name}} - {{item.location_name}}</h4>
                                                        <span class="app-action-status-gray-text">{{item.department_name}}</span>
                                                    </div>
                                                </div>
                                            </b-col>

                                            <b-col class="ml-1 pl-0">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 class="mb-0 text-sm font-weight-600" >{{item.cao_short_name}}</h4>
                                                        <span class="app-action-status-gray-text">{{item.function_name}}</span>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col cols="auto" ends>
                                                <b-row>
                                                    <b-col >
                                                        <h4 class="mb-0 text-sm font-weight-600 text-right" >{{item.shift_date}}</h4>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <span class="text-sm font-weight-400 text-right" v-html="getDateAndHours(item)" /> | <span class="text-sm font-weight-400 text-right">{{item.daytime_name}}</span>
                                                    </b-col>                                       
                                                </b-row>
                                            </b-col>
                                        </b-row>

                                        <b-row align-v="center" class="mt-1" v-if="item.status < 0">
                                            <b-col cols="auto" class="p-0 pl-1">
                                                <span class="app-action-status-red-text">{{trans(item.error, 296)}}</span>
                                            </b-col>
                                        </b-row>

                                        <b-row align-v="center" class="mt-1" v-if="item.atw_status == 0">
                                            <b-col cols="auto" class="p-0 pl-1">
                                                <div class="app-action-status-red-text" v-for="item_error in JSON.parse(item.atw_error)">{{trans(item_error, 313)}}</div>
                                            </b-col>
                                        </b-row>

                                        <b-row v-if="person != null && item.status == 0">
                                            <b-col cols="12" class="pl-0">
                                                <b-alert variant="warning" show class="mt-3 mb-0">
                                                    <div class="text-md-start">{{trans(item.error, 296)}}</div> 
                                                </b-alert> 
                                            </b-col>
                                        </b-row>

                                        <b-row  v-if="item.status == 1 && item.error == 'mb-rules-no-qualifications-warning'">
                                            <b-col cols="12" class="pl-0 mb-0">
                                                <b-alert variant="warning" show class="mt-3 mb-0">
                                                    <div class="text-md-start">{{trans(item.error, 296)}}</div> 
                                                </b-alert> 
                                            </b-col>
                                        </b-row>

                                    </b-col>

                                </b-row>
                                
                            </b-list-group-item>

                        </b-list-group>

                    </b-col>
                </b-row>
                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </b-card>

    </div>
</template>
<script>


import axios from "@axios";
import moment from "moment";

export default {
    components: { 
    },

    props: ["idsShiftDataMain", "person", "shiftsData", "loading", "planningParametersCheck", "loadingListShift"],

    computed: {
        cShiftsData: {
            get() { return this.shiftsData },
            set(value) { this.$emit('update:shiftsData', value) }
        },

        cPlanningParametersCheck: {
            get() { return this.planningParametersCheck },
            set(value) { this.$emit('update:planningParametersCheck', value) }
        },

        cLoading: {
            get() { return this.loading },
            set(value) { this.$emit('update:loading', value) }
        },

        cPerson:{
            get() { return this.person },
            set(value) { this.$emit('update:person', value) }
        }
    },

    mounted() {
        this.onCreateThis();
    },

    watch: {
        "person.applicant_type": {
            handler: function(val, oldVal) {
                if (val != oldVal) {
                    this.checkPerson();
                }                
            },
            deep:true
        },
    },

    data() {
        return {
            customColumns: ["selected"],
            loading_list: false,
            total: null,
            checkbox: '0',
            show_padding: false,
            applicant_type_list: [
                { value: 1, text: this.trans('salaried-service', 280) },
                { value: 2, text: this.trans('self-employed', 280) },
            ],
        };
    },


    methods: {
        checkPerson: function() {
            if (this.idsShiftDataMain != null && this.idsShiftDataMain.length > 0) {
                this.loading_list = true;
                this.$emit('update:loadingListShift', true);
                this.cLoading = true;
                
                axios
                    .post("shifts/person/checkPersonInShiftsWithData", {
                        ids_shift_data_main: this.idsShiftDataMain,
                        id_person_data_main: this.person.id,
                        applicant_type: this.person.applicant_type
                    })
                    .then((res) => {
                        this.cShiftsData = res.data;
                        this.total = this.cShiftsData.length;
                        if(res.data.length > 0){
                            for(var i=0; i<res.data.length; i++){
                                if(JSON.parse(res.data[i].planning_parameters).client_confirmation == null){
                                    this.cPlanningParametersCheck = res.data[i].id_client_data_main;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading_list = false;
                        this.cLoading = false;
                        this.show_padding = true;
                        this.$emit('update:loadingListShift', false);
                    });
            }
                
        },

        getDateAndHours(shift_item) { 
            let start_hour = parseInt(shift_item.hour_from.slice(0,2));
            let start_minutes = shift_item.hour_from.slice(3);
            let end_hour = parseInt(shift_item.hour_to.slice(0,2));
            let end_minutes = shift_item.hour_to.slice(3);

            return start_hour + '<sup class="ml-1">' + start_minutes + '</sup> - '+ end_hour + '<sup class="ml-1">' + end_minutes + '</sup> ';
        },

        getDayAndWeek(shift_item) {
            moment.locale('nl') 
            return moment(shift_item.shift_date).format('dddd') + ", wk " + moment(shift_item.shift_date).format('ww');
        },
   

        onCreateThis() { 
            this.checkPerson();


            this.$watch('person', function(newVal, oldVal) {
                if (newVal != null && !this.loading_list) {
                    this.checkPerson();
                }   
            });
        },
    }
 
};
</script>
<style scoped>

.app-local-width{
    min-width:24px; 
    max-width:24px;
}

.app-action-status-gray-text{
    font-size: 12px;
    color: #8898aa;
}

.app-action-status-red-text{
    font-size: 12px;
    color: #f04438 !important;
}

</style>