<template>
    <b-row>
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1"> 
            <div class="mb-3">
                <div class="mt-3 mb-1 d-flex align-items-center">
                <router-link :to="{name: 'planning-offers-shifts'}">
                        <b-icon icon="arrow-left" class="mr-2"></b-icon>
                        <span>{{trans('back-to-service',285)}}</span>
                </router-link>
                </div>
            </div>
            
            <h1 class="mt-4 mb-4">{{trans('register-flexworker',282)}}</h1>

            <b-alert variant="warning" show class="mb-3" v-if="planningParametersCheck != null">
                <div class="d-flex w-100 justify-content-between">
            
                    <div class="text-md-start mt-2">{{trans('planning-scheduling-information-not-entered',288)}}</div> 
                    <div class="d-flex align-items-center">
                        <app-button type="primary" :url="{ name: 'clients-profile-organization', params: { id_client_data_main: planningParametersCheck }}">{{trans('to-organization',285)}}</app-button>
                    </div>
                </div>
            </b-alert> 
      
            <b-row class="mb-3">
                <b-col>
                    <offers-shifts-person-add-search-component :idsShiftDataMain.sync="ids_shift_data_main" :person.sync="person" :startDate.sync="start_date" :loadingListShift.sync="loading_list_shift" :loadingListPlanner.sync="loading_list_planner" />
                </b-col>
            </b-row>
            
            <b-row class="mb-3" v-if="person != null">
                <b-col>
                    <offers-shifts-person-add-shifts-list-component  :idsShiftDataMain="ids_shift_data_main" :loading.sync="loading" :person.sync="person" :shiftsData.sync="shiftsData" :planningParametersCheck.sync="planningParametersCheck" :loadingListShift.sync="loading_list_shift"/>
                </b-col>
            </b-row>
            <div class="mt-4 mb-3" >
                <app-button type="primary" @click="registerPerson()" :loading="loading" :disabled="person == null || posibleClick || planningParametersCheck != null">
                    {{trans('planning-register',283)}}
                </app-button>
            </div>
        </b-col>
    </b-row>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import OffersShiftsPersonAddSearchComponent from './Components/OffersShiftsPersonAddSearchComponent.vue';
import OffersShiftsPersonAddShiftsListComponent from './Components/OffersShiftsPersonAddShiftsListComponent.vue';

export default {
    components: {
        OffersShiftsPersonAddSearchComponent,
        OffersShiftsPersonAddShiftsListComponent,

    },

    watch: {

    },

    computed: {
        posibleClick(){
           //var count = this.shiftsData.filter(field => field.status < 0 || field.atw_status == 0).length
           var count = this.shiftsData.filter(field => field.status >= 0 && field.atw_status == 1).length

            if(count > 0){
                return false;
            }else{
                return true;
            }

        }
    
    },

    data(){
        return {
            ids_shift_data_main: this.$route.params.ids_shift_data_main,
            start_date: this.$route.params.start_date,
            planningParametersCheck: null,
            shiftsData: [],
            person: null,
            alertClass: new AppAlerts(),
            client_confirmed: null,
            loading: false,
            loading_list_planner: false,
            loading_list_shift: false,
        }
    },

    methods:{   
        registerPerson(){
            //var badShifts = this.shiftsData.filter(field => field.status < 0).length
            var goodShifts = this.shiftsData.filter(field => field.status >= 0 && field.atw_status == 1).length
            
            if(goodShifts == 0){
                this.alertClass.openAlertWarning(this.trans('planning-cannot-add-person-to-service',211));
                return;
            }
            let data = this.shiftsData.filter(field => field.status >= 0 && field.atw_status == 1)
            
            this.loading = true;

                    axios
                        .post('shifts/person/register', {
                            shifts: JSON.stringify(this.shiftsData),
                            id_person_data_main: this.person.id,
                            id_favourite_data_client: this.person.id_favourite_data_client,
                            favourite_client_check: this.person.favourite_client_check
                        })
                        .then((res) => {
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.$router.push({ name: "planning-offers-shifts", params: { } });
                        });
        },
    }
}
</script>

<style scoped>

</style>